//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #CB83C7;
$gray: #656565;

//Fonts
$primary-font: 'Montserrat', sans-serif;
