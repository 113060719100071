@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $white;
    color: $black;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rems(16);
    line-height: 1.2;
    overflow: hidden;
}

a {
    color: inherit;
    transition: .3s all;
    text-decoration: none;

    @include hover {
        color: $primary-color;
    }
}

.container {
    max-width: rems(1090);
}

h2 {
    position: relative;
    font-size: rems(32);
    text-transform: uppercase;
    font-weight: 900;

    @media screen and (max-width: 800px) {
        font-size: rems(24);
    }

    &::after {
        content: '';
        width: rems(110);
        max-width: 50%;
        height: rems(6);
        position: absolute;

        left: 0;
        top: 100%;
        background-color: $primary-color;
    }
}

// BIT EMBED
bit-follow-section-wrapper {
    display: none;
}

bit-play-my-city-wrapper {
    display: none;
}

#header {
    padding: rems(10) 0 50vw;
    background-image: linear-gradient(to bottom, #00000099, #00000000), url('../img/hero-new.jpg');
    background-size: 100% rems(100), cover;
    background-position: top center, top center;
    background-repeat: no-repeat;

    @media screen and (max-width: 800px) {
        padding: rems(10) 0;
    }


    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    h1 {
        flex-basis: rems(300);
        margin: 0 0 rems(10);

        @media screen and (max-width: 800) {
            // flex-basis: rems(270);
            width: 100%;
        }
    }

    .nav {
        font-weight: 500;
        color: $white;
        margin: 0;
        padding: 0;
        list-style: none;

        .drop-options {
            width: auto;
        }

        >li {
            display: inline-block;
            position: relative;
            font-size: rems(14);
            line-height: 1;
            vertical-align: middle;
            text-transform: uppercase;

            &+li {
                margin-left: rems(20);
            }

            >ul {
                opacity: 0;
                position: absolute;
                z-index: 10;
                top: 100%;
                width: 100%;
                margin: 0;
                padding: rems(5);
                background: #000;
                list-style: none;

                >li {
                    padding: rems(5);
                    font-size: rems(14);
                    line-height: 1em;
                    text-align: center;
                }
            }

            @include hover {
                >ul {
                    opacity: 1;
                }
            }
        }
    }


    @media screen and (max-width: 800px) {
        height: rems(500);

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            height: 100%;
            padding-bottom: 0;

            .nav li+li {
                margin-left: rems(15);

                a {
                    line-height: 1.7em;
                }
            }
        }
    }
}

#release {
    // background-image: url('../img/background-texture.png');
    padding-bottom: rems(80);

    .wrap {
        color: $black;
        position: relative;
        display: flex;
        justify-content: initial;
        align-items: center;

        img {
            width: 100%;
            max-width: rems(525);
        }
    }

    .buy-links {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: rems(22);
        display: flex;

        li {
            padding: rems(5);
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .info {
        text-align: center;
        flex: 1;

        p {
            display: block;
            text-transform: uppercase;
            font-size: rems(52);
            font-weight: 700;
            margin: 0 0 .5em;

            span {
                display: block;
                font-size: rems(26);
            }
        }

        .btn {
            display: inline-block;
            text-align: center;
            padding: rems(15) rems(10);
            background-color: $black;
            color: $white;
            text-transform: uppercase;
            // box-shadow:  0 0 rems(10) #0006;
            font-size: rems(20);
            font-weight: 900;
            width: rems(160);

            @include hover {
                color: $white;
                background-color: $primary-color;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .wrap {
            flex-direction: column;

            .info {
                padding: rems(10) 0;
            }

            .info>div>img {
                display: none;
            }

            .info p {
                font-size: rems(20);
            }
        }
    }
}


#player {
    // margin-top: rems(-180);

    @media screen and (max-width: 800px) {
        margin-top: rems(20);

        .container {
            flex-direction: column;

            .flex {
                justify-content: space-between;
                padding: rems(30) rems(10);
                // padding-top: 11rem;

                img {
                    width: rems(100);
                    margin-bottom: rems(80);
                    // margin-left: auto;
                }

                a {
                    width: 100%;

                    i {
                        font-size: rems(18);
                    }
                }
            }
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .flex {
        height: rems(300);
        padding: rems(30);
        flex: 1;
        // background-image: url('../img/promo-new.jpg');
        // background-size: cover;
        // background-position: 25% 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        img {
            margin-bottom: rems(15);
        }

        a {
            margin: rems(5);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $black;
            padding: rems(5) rems(20) rems(5) rems(10);
            border: rems(2) solid $black;
            background-color: $white;

            i {
                margin-right: rems(10);
                font-size: rems(30);
            }

            span {
                font-size: rems(12);
                text-transform: uppercase;
                font-weight: 900;
            }

            @include hover {
                background-color: $black;
                color: $white;
            }
        }
    }

    .player {
        height: rems(300);
        flex-basis: rems(300);
        max-width: rems(600);
    }
}

#videos {
    padding: rems(10) 0 rems(80);

    h2 {
        margin: rems(40) 0;
    }

    .owl-next,
    .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: rems(40);
        color: #E7E6E6;

        @include hover {
            color: $black;
        }
    }

    .owl-prev {
        left: rems(-40);
    }

    .owl-next {
        right: rems(-40);
    }

    @media screen and (max-width: 1150px) {
        .owl-prevm .owl-next {

            text-shadow: 0 0 rems(5) #0006;
        }

        .owl-prev {
            left: rems(10);
        }

        .owl-next {
            right: rems(10);
        }
    }
}

section#tour {
    padding-bottom: rems(80);

    .container {}
}


#merch {
    margin-bottom: rems(80);
}

#follow {
    padding: rems(150) 0;
    background-image: url('../img/footer-new.jpg');
    background-size: cover;
    background-position: center;
    text-align: right;

    h2 {
        color: $white;
        text-shadow: 0 2px 8px #000000a3;

        &::after {
            right: 0;
            left: auto;
        }
    }

    a {
        display: inline-block;
        text-align: center;
        padding: rems(15) rems(10);
        background-color: $white;
        text-transform: uppercase;
        box-shadow: 0 0 rems(10) #0006;
        font-size: rems(20);
        font-weight: 900;
        width: rems(160);

        &+a {
            margin-left: rems(12);
        }
    }

    @media screen and (max-width: 800px) {
        padding: rems(60) 0;

        a {
            display: block;
            margin-left: auto;
        }

        a+a {
            margin: rems(15) 0 0 auto;
        }
    }
}

#bio {
    padding: rems(60) 0;

    h2::after {
        width: rems(30);
    }
}

#footer {
    background-color: $black;
    color: $white;

    .social {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: rems(22);

        li {
            display: inline-block;

            &+li {
                margin-left: .5em;
            }
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .copyright {
        width: rems(500);
        max-width: 100%;
        text-align: right;
        font-size: rems(10);
        text-transform: uppercase;
    }

    @media screen and (max-width: 800px) {
        text-align: center;

        .container {
            display: block;
            padding: rems(10);
        }

        .copyright {
            text-align: center;
            margin: rems(10) auto 0;
        }
    }
}